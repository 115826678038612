import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

// Components
import ProductCard from "../ProductCard/ProductCard";

// lodash
import _isEmpty from 'lodash/isEmpty';

//Styles
import './searchScreen.css';

// Images
import Jacket1 from 'assets/Jacket1.jpg'
import Jacket2 from 'assets/Jacket2.webp'
import Jacket3 from 'assets/Jacket3.jpg'
import Jacket4 from 'assets/Jacket4.webp'
import Jacket5 from 'assets/Jacket5.jpg'
import Jacket6 from 'assets/Jacket6.jpg'
import Jacket7 from 'assets/Jacket7.webp'
import Jacket8 from 'assets/Jacket8.webp'
import Jacket9 from 'assets/Jacket9.webp'

const demoText1 = "Bike hoodies in black color with bike patterns or logos in red"; // New search text
const demoText2 = "Show me with smaller patterns or logos";
const products = [
  {
    id: 1,
    name: "Bike = Life Jacket",
    image: Jacket1,
    description: "Bike = Life Jacket",
    price: "Rs. 1000",
  },
  {
    id: 2,
    name: "Red Star Jacket",
    image: Jacket2,
    description: "Bike = Life Jacket",
    price: "Rs. 899",
  },
  {
    id: 3,
    name: "Honda Oversized Bike Jacket",
    image: Jacket3,
    description: "Bike = Life Jacket",
    price: "Rs. 3999",
  },
  {
    id: 4,
    name: "Leather Jacket",
    image: Jacket4,
    description: "Bike = Life Jacket",
    price: "Rs. 1799",
  },
  {
    id: 5,
    name: "Biking Jacket 69",
    image: Jacket5,
    description: "Bike = Life Jacket",
    price: "Rs. 799",
  },
  {
    id: 6,
    name: "Bullet Rider Comfort Jacket",
    image: Jacket6,
    description: "Bike = Life Jacket",
    price: "Rs. 999",
  },
];

const products2 = [
  {
    id: 1,
    name: "Racing Line Black Hoodie",
    image: Jacket7,
    description: "Bike = Life Jacket",
    price: "Rs. 2999",
  },
  {
    id: 2,
    name: "Biker Heart Beat Black hoodie",
    image: Jacket8,
    description: "Bike = Life Jacket",
    price: "Rs. 699",
  },
  {
    id: 3,
    name: "Motorbike Red Lb Hoodie",
    image: Jacket9,
    description: "Bike = Life Jacket",
    price: "Rs. 1599",
  },
];

function SearchScreen({ onComplete }) {
  const [typedText, setTypedText] = useState("");
  const [tags, setTags] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showResults2, setShowResults2] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false); // Track the button click state

  useEffect(() => {
    const typeText = async () => {
      for (let i = 0; i <= demoText1.length; i++) {
        setTypedText(demoText1.slice(0, i));
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
      setTimeout(handleSearchClick, 1000); // Simulate button click after typing ends
    };
    typeText();
  }, []);

  const handleButtonResize = (tags) => {
    setButtonClicked(false); 
    setTags(tags);
    setTypedText("");
  };
  
  const handleSearchClick = () => {
    setButtonClicked(true); // Trigger button click animation
    setTimeout(() => {
      handleButtonResize([demoText1]);
    }, 300); // Matches the duration of button shrink animation
    setTimeout(() => {
      setShowResults(true); // Show results after button click animation
    }, 500); // Delay results to sync with button animation
  };

  const handleAnimationComplete = async () => {
    setTypedText("");
    setTags([demoText1]);
    for (let i = 0; i <= demoText2.length; i++) {
      setTypedText(demoText2.slice(0, i));
      await new Promise((resolve) => setTimeout(resolve, 50));
    }
    setButtonClicked(true); // Trigger button click animation
    setTimeout(() => {
      handleButtonResize([demoText1, demoText2]); // Reset button animation after delay
    }, 300); // Matches the duration of button shrink animation
    setTimeout(() => {
      setShowResults(false);
      setShowResults2(true);
    }, 1000);
  };

  const onProductAnimationComplete = (productId) => () => {
    if (productId === 6) {
      setTimeout(handleAnimationComplete, 500);
    }
    if(showResults2)
      setTimeout(onComplete, 2000)
  }
  
  const getResultsComponent = () => {
    if (showResults && !showResults2)
      return (<motion.div className="results-grid">
        {showResults &&
          products.slice(0, 6).map((product, index) => (
            <motion.div
              key={product.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.5, duration: 0.5 }}
              onAnimationComplete={onProductAnimationComplete(product.id)}
            >
              <ProductCard productName={product.name} productImage={product.image} productPrice={product.price} />
            </motion.div>
          ))}
      </motion.div>)
    if (showResults2 && !showResults)
      return (
        <motion.div className="results-grid">
          {showResults2 &&
            products2.slice(0, 6).map((product, index) => (
              <motion.div
                key={showResults2}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.5, duration: 0.5 }}
                onAnimationComplete={onProductAnimationComplete(product.id)}
              >
                <ProductCard productName={product.name} productImage={product.image} productPrice={product.price} />
              </motion.div>
            ))}
        </motion.div>);
  }

  return (
    <div className="search-screen">
      <div className="page-heading">
        <h2>Experience our conversational search</h2>
      </div>
      <div className="search-tags-container">
        <div className="search-container">
          <motion.input
            type="text"
            className="search-input"
            value={typedText}
            placeholder="Search..."
            readOnly
            initial={{ opacity: 0, y: -20 }}  // Starting point: offscreen (above)
            animate={{ opacity: 1, y: 0 }}    // Ending point: fully visible
            transition={{ duration: 1 }}      // Smooth transition
          />
          <motion.button
            className="search-button"
            animate={{
              scale: buttonClicked ? 0.8 : 1, // Shrink the button when clicked
              boxShadow: buttonClicked
                ? "0 0 10px rgba(0, 123, 255, 0.7)"
                : "0 0 20px rgba(0, 123, 255, 0.5)",
            }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
            }}
          >
            Search
          </motion.button>
        </div>
        <div className="tags">
          {!_isEmpty(tags) && tags.map((tag, index) => (
            <div key={index} className="tag-item">
              <span className="title">{tag}</span>
              <button className="remove-btn">&times;</button>
            </div>
          ))}
        </div>
      </div>
      {getResultsComponent()}
    </div>
  );
}

export default SearchScreen;
