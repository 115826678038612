import React from 'react';

import './productInformation.css';
import ProductRating from '../ProductRating';
import ProductPrice from '../ProductPrice';

import { Button, styled } from '@mui/material';
import { FaArrowTrendUp } from "react-icons/fa6";

const CustomButton = styled(Button)({
    fontSize: 22,
    padding: '18px 40px',
    border: '1px solid',
    lineHeight: 2,
});

const ProductInformation = (props) => {

    return (
        <div className='productInformationContainer'>
            <div className="productBrand"> Product Brand
            </div>
            <div className="productDescription"> Product Description something describing the product in brief
            </div>
            <ProductRating value={4} productRatingContainer={"productRatingsContainer"} productRatingsLabel={"productRatingsLabel"} />
            <ProductPrice />
            <div className="checkoutButton">
                <CustomButton size='large' variant="outlined" startIcon={<FaArrowTrendUp />}>Check Out Now</CustomButton>
            </div>

        </div >
    );
}

export default ProductInformation;
