import React, { useState } from 'react';

// Styles
import './home.css';

// Components
import IntroScreen from './components/IntroScreen/IntroScreen';
import SearchScreen from './components/SearchScreen/SearchScreen';
import TryOnScreen from './components/TryOnScreen/TryOnScreen';

const Home = () => {
  const [showSearchScreen, setShowSearchScreen] = useState(false);
  const [showTryOnScreen, setShowTryOnScreen] = useState(false);

  const handleTransitionToSearchScreen = () => {
    setShowSearchScreen(true);
  };

  const handleTransitionToTryOnScreen = () => {
    setShowSearchScreen(false);
    setShowTryOnScreen(true);
  };

  const handleTransitionToIntroScreen = () => {
    setShowSearchScreen(false);
    setShowTryOnScreen(false);
  };

  return (
    <div className='home'>
      {!showSearchScreen && !showTryOnScreen && <IntroScreen onComplete={handleTransitionToSearchScreen} />}
      {showSearchScreen && <SearchScreen onComplete={handleTransitionToTryOnScreen} />}
      {showTryOnScreen && <TryOnScreen onComplete={handleTransitionToIntroScreen} />}
    </div>
  );
};

export default Home;
