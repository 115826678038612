import React, { useState } from 'react';

import YfLogo from 'assets/yf-logo.png';
import Button from 'components/atoms/Button';
import CustomerForm from 'components/CustomerForm';
import { useNavigate } from "react-router-dom";

import { ArrowSquareOut } from '@phosphor-icons/react';

import './styles.css';
import VendorForm from 'components/VendorForm';

const AppHeader = () => {
  const [isCustomerFormOpen, setIsCustomerFormOpen] = useState(false);
  const [isVendorFormOpen, setIsVendorFormOpen] = useState(false);

  const navigate = useNavigate();

  const headerNavConfig = [
    {
      onClick: () => setIsVendorFormOpen(true),
      child: 'Partner with Us',
      variant: 'contained',
    },
    {
      onClick: () => setIsCustomerFormOpen(true),
      child: 'Join Waitlist',
      variant: 'contained',
    },
    {
      onClick: () => navigate("/demo"),
      child: <div className='flex-row align-items-center'>Demo&nbsp;<ArrowSquareOut /></div>,
      variant: 'outlined',
    }
  ];

  return (
    <div className='app-header-container bg-white flex-row justify-space-between align-items-center p-a-8'>
      <div className='align-items-center header-text flex-row cursor-pointer' onClick={navigate.bind(this, '')}>
        <img className='p-r-8' src={YfLogo} style={{ width: 63 }} />
        YourFash.ai
      </div>
      <div className='flex-row right-buttons-container'>
        {headerNavConfig.map(({ onClick, child, variant }) => <Button onClick={onClick} child={child} variant={variant} />)}
      </div>
      <CustomerForm handleClose={() => setIsCustomerFormOpen(false)} open={isCustomerFormOpen} />
      <VendorForm handleClose={() => setIsVendorFormOpen(false)} open={isVendorFormOpen} />
    </div>
  );
};

export default AppHeader;
