import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Global Styles
import 'styles/alignment.css';
import 'styles/colors.css';
import 'styles/cursor.css';
import 'styles/general.css';
import 'styles/spacing.css';
import 'styles/text.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

