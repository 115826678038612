import React from "react";

// Providers
import { SnackbarProvider } from "notistack";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// Components
import AppHeader from './components/AppHeader';
import Home from './pages/Home';
import TechDemo from './pages/TechDemo';
import ProductDisplay from './pages/ProductDisplay';

function App() {
  return (
    <div className="App">
      <SnackbarProvider>
        <BrowserRouter>
          <AppHeader />
          <Routes>
            <Route path="/demo" element={<TechDemo />} />
            <Route path="/product/:productId" element={<ProductDisplay />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </div>
  );
}

export default App;
