import React, { useState } from 'react';

// lodash
import _map from 'lodash/map';

// Components
import Carousel from 'react-bootstrap/Carousel';

// styles
import './imageCarousel.css';

function ImageCarousel({ slides, carouselContainerClassName ='', carouselImageClassName ='' }) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <div className={`${carouselContainerClassName}`}>
            <Carousel activeIndex={index}
                onSelect={handleSelect}
                interval={null}
            >
                {_map(slides, (slide) => (
                    <Carousel.Item key={slide.image} interval={slide.interval}>
                        <img
                            className={`d-block w-100 ${carouselImageClassName}`}
                            src={slide.image}
                        />
                        <Carousel.Caption>
                            <h3>{slide.title}</h3>
                            <p>{slide.subTitle}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}

export default ImageCarousel;