import React from 'react';

import './TechDemo.css';

const TechDemo = () => {
  return (
    <div className='demo-text'>Coming soon...</div>
  );
}

export default TechDemo;
