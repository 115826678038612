
import React from 'react';

// Hooks
import { useParams } from 'react-router-dom';

// Components
import ImageCarousel from 'components/ImageCarousel';
import ProductInformation from './components/ProductInformation';

//Assets
import Jacket1 from '../../assets/Jacket1.jpg';
import Jacket2 from '../../assets/Jacket2.webp';
import Jacket3 from '../../assets/Jacket3.jpg';

// Styles
import './productDisplay.css';


const slides = [
  {
    image: Jacket1,
  },
  {
    image: Jacket2,
  },
  {
    image: Jacket3,
  }
];

const ProductDisplay = () => {
  const { productId } = useParams();

  return (
    <div className='flex'>
      <ImageCarousel slides={slides} carouselContainerClassName='productDisplayContainer' carouselImageClassName='productImage' />
      <ProductInformation />
    </div>
  );
}

export default ProductDisplay;
