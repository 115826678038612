import React from "react";

//Styles
import './productCard.css';

const ProductCard = ({ productImage, productPrice, productName, productDescription, productImageClassName = "product-image", productCardClassName = "product-card", productCardImageContainerClassName = 'product-image-container' }) => {
  return (
    <div className={productCardClassName}>
      <div className={productCardImageContainerClassName}>
        <img
          src={productImage}
          alt={productName}
          className={productImageClassName}
        />
        <div className="product-name-overlay">
          <div className="product-name">{productName}</div>
          <div className="product-description">{productDescription}</div>
          <div className="product-price">{productPrice}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
