import React from 'react';

import { Card, CardContent, Rating } from '@mui/material';

const ProductRating = ({ value, productRatingContainer, productRatingsLabel }) => {

    return (
        <Card className={productRatingContainer}>
            <CardContent>
                <div className={productRatingsLabel}>Ratings</div>
                <Rating size={'large'} name="read-only" value={value} readOnly />
            </CardContent>
        </Card>
    );
}

export default ProductRating;
