import React from 'react';

import { Button as MaterialButton } from '@mui/material';

const Button = ({
  child,
  onClick,
  variant = 'text',
}) => {
  return (
    <MaterialButton className='bg-ocean-blue txt-white p-a-8' sx={{ml:1, textTransform: 'none'}} onClick={onClick} variant={variant}>
      {child}
    </MaterialButton>
  )
};

export default Button;
