import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

const LoaderOverlay = ({
  children,
  loading,
}) => (
  <>
    {children}
    <Backdrop open={loading}>
      <CircularProgress />
    </Backdrop>
  </>
);

export default LoaderOverlay;
