import React from "react";
import { Typography, Box } from "@mui/material";
import { BsTag } from "react-icons/bs";
import "./productPrice.css";

const PriceDisplay = ({ actualPrice = 1999, discount = 20 }) => {
  const calculateFinalPrice = (actualPrice, discount) => {
    return actualPrice - (actualPrice * (discount / 100));
  };

  const finalPrice = calculateFinalPrice(actualPrice, discount);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    }).format(price);
  };

  return (
    <div className='productPriceContainer'>
      <Typography variant="body1" className='productActualPrice'>
        MRP: {formatPrice(actualPrice)}
      </Typography>
      <Box className='productPriceTag'>
        <BsTag size={20} />
        <Typography variant="h5" component="span" fontWeight="bold">
          {formatPrice(finalPrice)}
        </Typography>
      </Box>
      <Box className='discountBadge'>
        <Typography variant="body2">
          {discount}% OFF
        </Typography>
      </Box>
      <Typography variant="body2" className='productSavings'>
        You Save: {formatPrice(actualPrice - finalPrice)}
      </Typography>
    </div>
  );
};

export default PriceDisplay;
