import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'components/atoms/Button';
import Modal from '@mui/material/Modal';
import { useSnackbar } from 'notistack';
import { Checkbox, TextField, Typography } from '@mui/material';

import YfLogo from 'assets/yf-logo.png';
import { submitInterestForm } from 'services/formService';
import LoaderOverlay from 'components/atoms/LoaderOverlay';

const VendorForm = ({
  handleClose,
  open,
}) => {
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [brandName, setBrandName] = useState();
  const [brandWebsite, setBrandWebsite] = useState();
  const [departments, setDepartments] = useState();
  const [categories, setCategories] = useState();
  const [affiliateNetwork, setAffiliateNetwork] = useState();
  const [affiliateId, setAffiliateId] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const submitVendorForm = () => {
    setIsSubmitting(true);
    submitInterestForm({
      name, mobile: phone, email, brandName, brandWebsite, departments, categories, affiliateNetwork, affiliateId, formType: 'vendor'
    })
      .then(() => {
        enqueueSnackbar('Form Submitted!', { variant: 'success' })
        handleClose();
      })
      .catch(() => enqueueSnackbar('Something went wrong...', { variant: 'error' }))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box className='form-modal-container bg-white p-a-16 o-y-scroll b-r-8' style={{ maxHeight: '90%' }}>
        <div className='flex-column align-items-center'>
          <img src={YfLogo} alt="logo" width="18%" />
          <br />
          <Typography variant='body2'>
            <b>YourFash.ai</b> is redefining the online shopping experience with cutting-edge AI technology.
            Our
            platform offers ultra-realistic virtual try-ons and the most intelligent, personalized fashion
            search and discovery available. We're pushing the boundaries of online shopping, delivering an
            immersive experience that goes beyond what users thought was possible.
            <br /><br />
            We're really excited to have you shop with us! Please fill in your information accurately to
            stay updated.
          </Typography>
          <br />
        </div>
        <LoaderOverlay loading={isSubmitting}>
          <form className='flex-column'>
            <TextField
              label="Name"
              type="text"
              onChange={(e) => setName(e.target.value)}
              placeholder="Full Name"
              required
              variant='standard'
            />
            <TextField
              label="Phone"
              type='number'
              onChange={(e) => setPhone(e.target.value)}
              placeholder="9999999999"
              variant='standard'
            />
            <TextField
              label="Email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="email@example.com"
              required
              variant='standard'
            />
            <TextField
              label="Brand Name"
              type="text"
              onChange={(e) => setBrandName(e.target.value)}
              placeholder="Brand Label"
              required
              variant='standard'
            />
            <TextField
              label="Brand Website"
              type="text"
              onChange={(e) => setBrandWebsite(e.target.value)}
              placeholder="https://www.yourbrand.com"
              required
              variant='standard'
            />
            <br />
            <Typography>Departments</Typography> <Typography>(select all that apply)</Typography>
            <span>
              <Checkbox
                value="men"
              />Men
            </span>
            <span>
              <Checkbox
                value="women"
              />Women
            </span>
            <span>
              <Checkbox
                value="unisex"
              />Unisex
            </span>
            <br />
            <Typography>Departments</Typography> <Typography>(select all that apply)</Typography>
            <span>
              <Checkbox
                value="apparel"
              />Apparel
            </span>
            <span>
              <Checkbox
                value="accessories"
              />Accessories
            </span>
            <TextField
              label="Affiliate Network"
              type="text"
              onChange={(e) => setAffiliateNetwork(e.target.value)}
              placeholder="like Rakuten, Partnerize, Awin"
              required
              variant='standard'
            />
            <TextField
              label="Affiliate ID"
              type="text"
              onChange={(e) => setAffiliateId(e.target.value)}
              placeholder="ID"
              required
              variant='standard'
            />
            <br />
            <Button child="Submit" onClick={submitVendorForm} variant='contained' />
            <br />.
          </form>
        </LoaderOverlay>
      </Box>
    </Modal>
  );
};

export default VendorForm;
